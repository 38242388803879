<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-53.mp3"),
      },
      timeStampList: [4.5, 6.5, 8.8, 13.2, 18.4, 21],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Wǒmen xǐhuan gōngyuán.",
            chineseWords: "我们喜欢公园。",
          },
          {
            id: 2,
            spelling: "Wǒmen xiǎng qù gōngyuán.",
            chineseWords: "我们想去公园。",
          },
          {
            id: 3,
            spelling: "Nàr yǒu hěn duō gǒu. Zhèr yǒu hěn duō yú.",
            chineseWords: "那儿有很多狗；这儿有很多鱼。",
          },
          {
            id: 4,
            spelling: "Ā! Hěn gāoxìng rènshi nǐ.",
            chineseWords: "啊！很高兴认识你。",
          },
          {
            id: 5,
            spelling: "Wǒ yě hěn gāoxìng rènshi nǐ.",
            chineseWords: "我也很高兴认识你。",
          },
        ],
      },
    };
  },
};
</script>
